import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation, Trans } from 'next-i18next'
import { useRouter } from 'next/router'
import Image from 'next/image'
import classNames from 'classnames'
import { useSiteInfo } from 'shared'
import { MDropdown, MDropdownOption } from '../molecules/m-dropdown'
import { AppContext, AppContextType } from '../../context/app'
import useSettings from '../../hooks/use-settings'
import { ASortByRadio } from '../atoms/a-sort-by-radio'

interface CSortFilterProps {
  map?: JSX.Element
  isMapOpen: boolean
  setIsMapOpen: (param: boolean) => void
}
export const CSortFilter = ({
  map,
  isMapOpen,
  setIsMapOpen,
}: CSortFilterProps) => {
  const settings = useSettings()
  const router = useRouter()
  const siteInfo = useSiteInfo()

  const { t } = useTranslation('common')
  const appContext = useContext(AppContext) as AppContextType
  const [sortBy, setSortBy] = useState<string>('')

  const radiusOptions: Array<MDropdownOption> = useMemo(() => {
    return appContext.state.radius.options.map((option) => ({
      option: `${option.label} ${settings.unit.label}`,
      value: option.label,
      selected: appContext.state.query.radius
        ? appContext.state.query.radius === option.label
        : appContext.state.radius.default === option.label,
    }))
  }, [appContext.state.query.q, appContext.state.radius])

  const handleRadiusChange = (option: MDropdownOption) => {
    const query = {
      ...router.query,
      radius: option.value,
    }

    const queryParams = new URLSearchParams(query).toString()
    router.push(`/?${queryParams}`)
  }

  const handleSortChange = (value: string) => {
    const query = {
      ...router.query,
      sort: value,
    }

    const queryParams = new URLSearchParams(query).toString()
    router.push(`/?${queryParams}`, undefined, { shallow: true })
    setSortBy(value)
  }

  const results = useMemo(() => {
    return appContext.state.doctors.length
  }, [appContext.state.doctors])

  const currentSearch = useMemo(() => {
    return appContext.state.query.q ? appContext.state.query.q : 'zip code'
  }, [appContext.state.query])

  useEffect(() => {
    setSortBy(appContext?.state?.query?.sort || settings.sort.default)
  }, [appContext?.state?.query?.sort])

  return (
    <div className="flex flex-col items-start">
      <div className="block md:flex items-center text-lg w-full py-6 lg:py-8">
        {radiusOptions.length > 1 && (
          <>
            <div>{t('filter-by')}</div>
            <div className="flex items-center text-lg mt-2.5 md:mt-0">
              <div className="max-w-36 md:ml-3.5">
                <MDropdown
                  fullRounded
                  options={radiusOptions}
                  onChange={handleRadiusChange}
                />
              </div>
              <div className="ml-2">{'radius'}</div>
            </div>
          </>
        )}
      </div>
      {settings.sort.options.length > 1 && (
        <div
          className={`${
            siteInfo.isUS ? 'md:flex' : ''
          } hidden items-center min-h-8 font-semibold text-gray-800 font-body font-base mt-4 md:mt-0`}
        >
          <p className="mr-4 ">{t('sort-by')}:&nbsp;</p>
          <div className="flex gap-3 md:gap-5 flex-wrap">
            {settings.sort.options
              .filter((val) => val.label !== 'tiered')
              .map((option) => (
                <ASortByRadio
                  name="sort_by"
                  key={option.label}
                  value={option.label}
                  label={t(option.label)}
                  isChecked={sortBy === option.label}
                  onChange={handleSortChange}
                />
              ))}
          </div>
        </div>
      )}
      <div className="py-4 flex flex-wrap justify-between items-center w-full md:w-[68%] relative">
        <div className="w-full md:w-auto mb-6 md:mb-0">
          <Trans
            i18nKey="showing-results"
            values={{
              results: results ?? 0,
              currentSearch,
            }}
            components={{
              span: <span className="underline" />,
            }}
          />
        </div>
        <div
          className={classNames('w-full', {
            'invisible absolute -z-50': !isMapOpen,
          })}
        >
          {map}
        </div>
        <div className="md:hidden">
          {map && (
            <button
              className="flex items-center gap-2 underline text-primary-dark-teal text-md font-normal"
              onClick={() => setIsMapOpen(!isMapOpen)}
            >
              {isMapOpen ? (
                <>{t('hide-map')}</>
              ) : (
                <>
                  {t('open-map')}
                  <Image
                    src="/img/map-icon.png"
                    alt="map"
                    width={15}
                    height={15}
                  />
                </>
              )}
            </button>
          )}
        </div>
        {settings.sort.options.length > 1 && (
          <div
            className={` ${
              siteInfo.isUS ? 'md:hidden' : ''
            } flex items-center min-h-8 font-semibold text-gray-800 font-body font-base mt-4 md:mt-0`}
          >
            <p className="mr-4 ">{t('sort-by')}:&nbsp;</p>
            <div className="flex gap-3 md:gap-5 flex-wrap">
              {settings.sort.options
                .filter((val) => val.label !== 'tiered')
                .map((option) => (
                  <ASortByRadio
                    name="sort_by_mobile"
                    key={option.label}
                    value={option.label}
                    label={t(option.label)}
                    isChecked={sortBy === option.label}
                    onChange={handleSortChange}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
